import React, { useEffect, useState } from 'react'

import { Task } from '@mui/icons-material';
import { MDBBadge, MDBBtn } from 'mdb-react-ui-kit';
import Bold from '../../utils/Bold';
import { Divider } from '@mui/material';
import { Task  as TaskProps} from '../../app.interface';
import useUser from '../../hooks/useUser';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import toast from 'react-hot-toast';
import { ClipLoader, PulseLoader, ScaleLoader } from 'react-spinners';
import { docQr } from '../../Logics/docQr';
import { useSelector } from 'react-redux';
import MakeAsClosed from './makeAsClosed';
import { Message } from './interface';
import { updateData } from '../../Logics/updateData';

interface Props{
    task:TaskProps
    message:Message,
    details:any
}
const ChatTask:React.FC<Props>=({task,details,message})=>{
    const {user}=useUser()

    const [isLoading,setIsLoading]=useState<boolean>(false);
const [applied,setApplied]=useState<boolean>(false);
const isAdmin=useSelector((root:any)=>root.isAdmin.isAdmin);

async function checkApplyStatus(){
    const checkData=await docQr(task.taskId+"_Applications",{
        max:1,
        whereClauses:[
            {
                field:"uid",
                operator:"==",
                value:user.uid
            }
        ]
    })
    if(checkData.length > 0){
        setApplied(true)
    }
}
useEffect(()=>{
checkApplyStatus()
},[])
const apply=async ()=>{
try{
    setIsLoading(true);

    await AddData(collection(db,task.taskId+"_Applications"),{
        name:user.username,
        uid:user.uid
    })
    toast.success("Applied successfully")
checkApplyStatus()

}
catch(err:any){
    toast.error(err.message ||"Something went wrong")
}
finally{
    setIsLoading(false)
}
}

const [isClosing,setIsClosing]=useState<boolean>(false)

const toggleTaskApplication=async ()=>{
    const {chats,currentChatRoom,setChats,setCurrentChatRoom}=details;
    // console.log({chats,currentChatRoom,setChats,setCurrentChatRoom})
   
    // console.log(current,message)
    const newMessage={...message,task:{...task,isClosedOnChat:task.isClosedOnChat ? false:true}}
    // console.log(newMessage);

    const chats_=chats.map((e:any)=>{
        if(e.task.taskId===task.taskId){
            return newMessage
        }
        return e;
      })
// console.log(chats_)
    try{
        setIsClosing(true)
     await updateData(currentChatRoom.roomId + "_Chat",message.docId as string,newMessage)
setChats(chats_)
    }
    catch(err:any){
        // console.log(err)
        toast.error(err.message || 'Something went wrong' )
    }
    finally{
        setIsClosing(false)
    }
}
    return (
        <>
   <div className='box' style={{background:"white",borderRadius:10,padding:7,boxShadow:"1px 1px 30px 0px lightgrey"}}>

<div className='d-flex justify-content-between'>
   <div style={{ gap: 10, padding: 4 }} className='d-flex '>
       <div className='d-flex align-items-center justify-content-center' style={{ borderRadius: 10, width: 30, height: 30, padding: 10, "background": 'rgba(0,0,200,0.09)' }}><Task style={{color: 'var(--blue)'}}/></div>

       <Bold style={{fontSize:'small'}}>Start <MDBBadge color='primary' style={{color:"white"}}>{task.startTime}</MDBBadge> | Finish <MDBBadge  style={{color:"white"}} color='primary'>{task.finishTime}</MDBBadge></Bold></div>

  {isAdmin && <>{isClosing ? <ClipLoader size={15}/>:<MakeAsClosed Task={task} click={toggleTaskApplication}/>}</>}
       </div>
   <Divider />
   <h4 style={{ padding: 5, marginTop: 3,fontSize:16 }}>
       <span style={{ fontWeight: '350' }}>
           {task.heading}
       </span>
   </h4>
<div className='d-flex justify-content-between'>     <span style={{ fontSize: 'small', padding: 5 }}>
       Dateline :  {task.dateLine}
   </span >

  {task.isClosedOnChat ? <MDBBtn rounded size='sm' disabled color='dark'>Closed</MDBBtn>: <MDBBtn disabled={applied} onClick={()=>{
    apply()
  }} style={{boxShadow:'none'}} size='sm' color='dark' rounded>{isLoading ? <PulseLoader size={10} color='white'/>:applied ? 'Applied':"Apply"} </MDBBtn>}


</div>


</div>
<div></div>

</>
    )
}

export default ChatTask;