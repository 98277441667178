import React from 'react'
import Bold from '../../utils/Bold';
import { FaEllipsisV } from 'react-icons/fa';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { Work, WorkOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { Edit, Trash } from 'react-feather';
import { User } from '../../app.interface';
import { deleteData } from '../../Logics/deleteData';
import toast from 'react-hot-toast';
import { updateData } from '../../Logics/updateData';
import swal from 'sweetalert'
interface Props{
    editCallback:()=>void,
    user:User,
    fetchUsers:()=>void,
    taskCallBack:()=>void
}
const UserCard:React.FC<Props>=({editCallback,user,fetchUsers,taskCallBack})=>{


    const delete_=async ()=>{
        let id:string ="";
    try{
    const yes=await swal({
        title:"Are you sure?",
        buttons:["No","Yes delete"],
        text:`Are you sure you want to delete this ${user.username}? this user will be permanently deleted and this operation cannot be reversed`
    })
    if(yes){
        id=toast.loading("Deleting...");

    await deleteData("Users",user.docId as  string);
    toast.success("User deleted successfully")
    fetchUsers()
    }

}
catch(err:any){
    toast.error(err.message || "Something went wrong")
}
finally{
   if(id) toast.dismiss(id);
    
}
}

const suspendUser=async ()=>{
const id=toast.loading("suspending "+user.username+"..." );
try{
await updateData("Users",user.docId as string,{...user,suspended:true})
fetchUsers()
toast.success("Operation successful")
}
catch(err:any){
    toast.error(err.message || "Something went wrong")
}
finally{
    toast.dismiss(id);
}
}

const unsuspendUser=async ()=>{

    const id=toast.loading("unsuspending "+user.username+"..." );
    try{
    await updateData("Users",user.docId as string,{...user,suspended:false})
    fetchUsers()
    toast.success("Operation successful")
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        toast.dismiss(id);
    }
    
}

    return (
        <>
        <div className='userCard d-flex justify-content-between align-items-center'>

            <div className='d-flex align-items-center ' style={{gap:10}}>
<Avatar/>
<div>
<Bold>{user.username}</Bold><br/>


<div  style={{gap:10}}><small>{user.email} <br/></small>
{user?.accountNo && <small>Acc No: {user?.accountNo} <br/></small>}
{user?.accountName && <small>Acc Name: {user?.accountName} <br/></small>}
{user?.bankName && <small>Bank: {user?.bankName} </small>}
</div>
{user.suspended ?  <Tooltip placement='top' title={`This user can login but won't be able to do any task`}><MDBBadge style={{marginLeft:3}} color='danger'>suspended</MDBBadge></Tooltip>:''}
    </div>
    </div> 

<MDBDropdown>
      <MDBDropdownToggle tag='div' className='noEffect' split ><IconButton style={{fontWeight:"400"}}><FaEllipsisV size={15} style={{fontWeight:"300"}}/></IconButton></MDBDropdownToggle>
      <MDBDropdownMenu>

<MDBDropdownItem link onClick={()=>{
    taskCallBack()
}}>
   <WorkspacePremiumOutlined/>  Show Tasks
</MDBDropdownItem>


        <MDBDropdownItem onClick={()=>{
            editCallback()
        }} link><Edit/> Edit</MDBDropdownItem>
        <MDBDropdownItem onClick={()=>{
            if(user?.suspended){
                unsuspendUser();
            }
            else {
                suspendUser()
            }
        }}  link><WorkOutline/> {user?.suspended? "unSuspend":"Suspend"}</MDBDropdownItem>
        <MDBDropdownItem onClick={()=>{
            delete_()
        }} link><Trash/> Delete</MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>

        </div>
        </>
    )
}

export default UserCard;