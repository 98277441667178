import React, { useEffect, useMemo, useState } from 'react'
import { Divider } from '@mui/material';
import ScrollBar from 'react-perfect-scrollbar';
import useInnerWidth from '../../hooks/useInnerWidth';
import { Task as TaskProps, User } from '../../app.interface';
import CardSkeleton from '../../utils/cardSkeleton';
import useUser from '../../hooks/useUser';
import Buttons, { ButtonsProps } from '../dashboard/sub/buttons';
import { fetchTasks_completed, fetchTasks_inprogress, fetchTasks_newest, fetchTasks_paid } from '../dashboard/fetchTasks';
import TaskListCard from '../dashboard/taskListCard';
import { formatToNaira } from '../../Logics/date';
import { MDBBtn } from 'mdb-react-ui-kit';
import toast from 'react-hot-toast';
import { deleteData } from '../../Logics/deleteData';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { getCurrentTimestamp } from '../../Logics/DateFunc';

interface Props{
    user:User
}


export const formatCurrency = (amount:number, currency:string) => {
    switch (currency) {
        case 'USD':
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // $1,234.56
        case 'NGN':
            return amount.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }); // ₦1,234.56\
            case 'EUROPE':
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'EUR' }); // €1.234,56

        case 'EURO':
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'EUR' }); // €1.234,56
        default:
            return amount.toLocaleString(); // Fallback for unknown currencies
    }
};


const UserTask:React.FC<Props>=({user})=>{
    const width=useInnerWidth()
    const [taskType, setTaskType] = useState<"In progress" | "Completed" | "Newest" | "Paid">("Newest")

    const [myWidth, setMyWidth] = useState<number>(0);
    const [tasks, setTasks] = useState<TaskProps[]>([]);
const [buttonsList,setButtonsList] = useState<ButtonsProps[]>([
        
        {
            name:"Newest",
            onClick:function(){
    setTaskType("Newest")
    setSelectedTasks([])
    markAsActive('Newest')
            },
            color:"primary"
        },
        {
            name:"In progress",
            onClick:function(){
                markAsActive("In progress")
    setTaskType("In progress")
    setSelectedTasks([])

            },
            color:"secondary"
        },
        {
            name:"Completed",
            onClick:function(){
                markAsActive("Completed")
    setTaskType("Completed")
    setSelectedTasks([])

            },
            color:"secondary"
        },
        {
            name:"Paid",
            onClick:function(){
                markAsActive("Paid")
    setTaskType("Paid")
    setSelectedTasks([])

            },
            color:"secondary"
        },
])

function markAsActive(objName:string){
    console.log(objName,buttonsList)
  const obj_ = buttonsList.map((e:ButtonsProps)=>{
if(e.name==objName)e.color='primary'
else e.color='secondary'
return e;
    })
setButtonsList([...obj_])
}
const [isLoadingTasks,setIsLoadingTasks]=useState<boolean>(true)
    const getTypeTask = async (taskType: "In progress" | "Completed" | "Newest" | "Paid") => {
        setIsLoadingTasks(true)
        let tasks_ = tasks
        switch (taskType) {
            case 'Newest':
                tasks_ = await fetchTasks_newest(user);
                break;
            case "In progress":
                tasks_ = await fetchTasks_inprogress(user);
                break;
            case "Completed":
                tasks_ = await fetchTasks_completed(user);
                break;
            case "Paid":
                tasks_ = await fetchTasks_paid(user);
                break;
            

        }
        setTasks(tasks_);
        setIsLoadingTasks(false);
    }

    useEffect(() => {
        // console.log("reload",taskTypes)
        setMyWidth((window.innerWidth - 20) - (document.querySelector(".sideNav") as HTMLDivElement)?.getBoundingClientRect()?.width || 0);
        getTypeTask(taskType)

    }, [taskType])

    useEffect(() => {
    }, [])
const [showDetails,setShowDetails]=useState<boolean>(false);
const [currentTask,setCurrentTask]=useState<TaskProps>()
    const toggleShowDetails=()=>{
        setShowDetails(!showDetails)
    }


    const [selectedTasks,setSelectedTasks]=useState<TaskProps[]>([]);
    const totalUSD: number = useMemo(() => {
        if (!Array.isArray(tasks) || tasks.length === 0) return 0;
        return tasks.filter((E)=>E.currency==="USD" || E.amount.includes("$")).reduce((sum, task, i) => {
          // Extract numbers from string (removes $, #, and other non-numeric characters)
          const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
          return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
        }, 0);
      }, [tasks]);

      const totalNGN: number = useMemo(() => {
        if (!Array.isArray(tasks) || tasks.length === 0) return 0;
        return tasks.filter((E)=>E.currency==="NGN" || (E.amount.includes("₦")||E.amount.includes("#"))).reduce((sum, task, i) => {
          // Extract numbers from string (removes $, #, and other non-numeric characters)
          const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
          return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
        }, 0);
      }, [tasks]);

      const totalEUROPE: number = useMemo(() => {
        if (!Array.isArray(tasks) || tasks.length === 0) return 0;
        return tasks.filter((E)=>E.currency==="EUROPE" || E.amount.includes("€")).reduce((sum, task, i) => {
          // Extract numbers from string (removes $, #, and other non-numeric characters)
          const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
          return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
        }, 0);

      }, [tasks]);
const [workingOnSelectedTask,setWorkingOnSelectedTask]=useState<boolean>(false);
const MarkAllASPaid_=async ()=>{
    try{
        setWorkingOnSelectedTask(true)
        // console.log("Tasks_completed" + user?.uid,task.docId as string);
        const id=toast.loading("moving selected tasks to paid section...");
        for(let i=0;i<selectedTasks.length;i++){
            const task=selectedTasks[i];
await deleteData("Tasks_completed" + user?.uid,task.docId as string)
await AddData(collection(db,"Tasks_paid" + (user?.uid as string || "")),{...task,status:'completed & paid',paidAt:getCurrentTimestamp()})
        }
        if(getTypeTask)await getTypeTask(taskType)
    toast.dismiss(id);
toast.success("Operation successful")
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        setWorkingOnSelectedTask(false)
    }
}





const hasSelection=taskType==='Completed';
    return (
        <div style ={{width:"100%"}}>
<Buttons buttonsList={buttonsList}/>
<br/>
<Divider/>



<div className='taskListCardContainer'>

<div className={`d-flex align-items-center justify-content-between`} style={{gap:5}}>
    <div  className={`d-flex align-items-center justify-content-between`} style={{background:"white",boxShadow:"1px 1px 10px 0px lightgray",borderRadius:10,padding:10,width:"100%"}}>
    <b>Totals</b>
        <span>{formatCurrency(totalUSD, 'USD')} </span>
        |
        <span>{formatCurrency(totalNGN, 'NGN')} </span>
        |
        <span>{formatCurrency(totalEUROPE, 'EURO')} </span>
</div>

{selectedTasks.length >0 && <MDBBtn size={`sm`} style={{minWidth:"max-content"}} disabled={workingOnSelectedTask} onClick={()=>MarkAllASPaid_()} rounded>{workingOnSelectedTask ? `Please wait...`:`Move  (${selectedTasks.length}) to paid`}</MDBBtn>}

</div>



    <ScrollBar style={{height:'70vh'}}>
        {!isLoadingTasks && tasks.length===0 && <div className='text-center' style={{padding:30}}>
            No Data Here
            </div>}
        {isLoadingTasks && [1,2,3,4,5,6].map((e:any)=>{
            return<div style={{width:"100%"}}> <CardSkeleton key={e}/></div>
        })}
    { !isLoadingTasks && tasks.map((task:any,i:number)=>(<TaskListCard hasSelection={hasSelection} onSelected={(selectedTask: TaskProps) => {
  setSelectedTasks((prev) => {
    // Check if task is already selected
    const isAlreadySelected = prev.some((E) => E.docId === selectedTask.docId);

    if (isAlreadySelected) {
      // Remove the task if already selected
      return prev.filter((e) => e.docId !== selectedTask.docId);
    } else {
      // Add the task if not selected
      return [...prev, selectedTask];
    }
  });
}}
 selected={selectedTasks.filter((E)=>E.docId===task.docId).length>0} getTypeTask={()=>getTypeTask(taskType)} user={user} setCurrentTask={setCurrentTask} setShowDetails={setShowDetails} task={task} key={i}/>))}
</ScrollBar>

</div>




        </div>
    )
}

export default UserTask;