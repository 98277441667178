import { MDBBtn } from 'mdb-react-ui-kit'
import React from 'react'
import { docQr } from '../Logics/docQr'
import { TaskSharp } from '@mui/icons-material'
import { updateData } from '../Logics/updateData'
import { Task } from '../app.interface'

const Test = () => {

  const run=async ()=>{
    
try{
  // return console.warn("no test to run")Tasks_paid Tasks_inprogress
const tasks=await docQr("Tasks_completed",{max:1000});
console.log('before',tasks)
for(let i = 0;i< tasks.length;i++){
  const task:Task=tasks[i];
  const currency=task?.amount?.includes("$") ? "USD":"NGN";
  const update=await updateData("Tasks",task.docId as string,{...task,currency})
  console.log(update);
  // console.log('update data:',i);
}
const tasks1=await docQr("Tasks");
console.log("after",tasks1);


}
catch(err:any){
  console.log(err);
}
finally{

}

  }


  return (
    <MDBBtn onClick={run}>
run test
        </MDBBtn>
  )
}

export default Test