import React, { useEffect, useMemo, useState } from 'react'
import Buttons, { ButtonsProps } from './sub/buttons';
import { Divider } from '@mui/material';
import TaskListCard from './taskListCard';
import ScrollBar from 'react-perfect-scrollbar';
import Quotes from './sub/Quotes';
import useInnerWidth from '../../hooks/useInnerWidth';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import TaskDetails from './taskDetails';
import { Task as TaskProps } from '../../app.interface';
import { fetchTasks_completed, fetchTasks_inprogress, fetchTasks_newest, fetchTasks_paid } from './fetchTasks';
import CardSkeleton from '../../utils/cardSkeleton';
import useUser from '../../hooks/useUser';
import { sortObjectsByDate } from '../../Logics/date';
import { formatCurrency } from '../Admin/userTask';

const DashboardLists:React.FC=()=>{
    const width=useInnerWidth()
    const [taskType, setTaskType] = useState<"In progress" | "Completed" | "Newest" | "Paid">("Newest")
    const { user } = useUser();
    const [myWidth, setMyWidth] = useState<number>(0);
    const [tasks, setTasks] = useState<TaskProps[]>([]);
const [buttonsList,setButtonsList] = useState<ButtonsProps[]>([
    
        {
            name:"Newest",
            onClick:function(){
    setTaskType("Newest")
    
    markAsActive('Newest')
            },
            color:"primary"
        },
        {
            name:"In progress",
            onClick:function(){
                markAsActive("In progress")
    setTaskType("In progress")
            },
            color:"secondary"
        },
        {
            name:"Completed",
            onClick:function(){
                markAsActive("Completed")
    setTaskType("Completed")
            },
            color:"secondary"
        },
        {
            name:"Paid",
            onClick:function(){
                markAsActive("Paid")
    setTaskType("Paid")
            },
            color:"secondary"
        },
    
])


function markAsActive(objName:string){
    // console.log(objName,buttonsList)
  const obj_ = buttonsList.map((e:ButtonsProps)=>{
if(e.name==objName)e.color='primary'
else e.color='secondary'
return e;
    })
setButtonsList([...obj_])
}
const [isLoadingTasks,setIsLoadingTasks]=useState<boolean>(true)

    const getTypeTask = async (taskType: "In progress" | "Completed" | "Newest" |"Paid") => {
        setIsLoadingTasks(true)
        let tasks_ = tasks
        switch (taskType) {
            case 'Newest':
                tasks_ = await fetchTasks_newest(user);
                break;
            case "In progress":
                tasks_ = await fetchTasks_inprogress(user);
                break;
            case "Completed":
                tasks_ = await fetchTasks_completed(user);
                break;
            case "Paid":
                tasks_ = await fetchTasks_paid(user);
            break
            default:
                tasks_ = await fetchTasks_newest(user);
                break;
        }
        setTasks(sortObjectsByDate(tasks_));
        setIsLoadingTasks(false);
    }

    useEffect(() => {
        //  console.log("reload",taskType)
        setMyWidth((window.innerWidth - 20) - (document.querySelector(".sideNav") as HTMLDivElement)?.getBoundingClientRect()?.width || 0);
        getTypeTask(taskType)

    }, [taskType])

    useEffect(() => {
    }, [])

    useEffect(()=>{
console.log({isLoadingTasks,tasks});
    },[isLoadingTasks,tasks])
const [showDetails,setShowDetails]=useState<boolean>(false);
const [currentTask,setCurrentTask]=useState<TaskProps>()
    const toggleShowDetails=()=>{
        setShowDetails(!showDetails)
    }










        const totalUSD: number = useMemo(() => {
            if (!Array.isArray(tasks) || tasks.length === 0) return 0;
            return tasks.filter((E)=>E.currency==="USD" || E.amount.includes("$")).reduce((sum, task, i) => {
              // Extract numbers from string (removes $, #, and other non-numeric characters)
              const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
              return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
            }, 0);
          }, [tasks]);
    
          const totalNGN: number = useMemo(() => {
            if (!Array.isArray(tasks) || tasks.length === 0) return 0;
            return tasks.filter((E)=>E.currency==="NGN" || (E.amount.includes("₦")||E.amount.includes("#"))).reduce((sum, task, i) => {
              // Extract numbers from string (removes $, #, and other non-numeric characters)
              const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
              return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
            }, 0);
          }, [tasks]);
    
          const totalEUROPE: number = useMemo(() => {
            if (!Array.isArray(tasks) || tasks.length === 0) return 0;
            return tasks.filter((E)=>E.currency==="EUROPE" || E.amount.includes("€")).reduce((sum, task, i) => {
              // Extract numbers from string (removes $, #, and other non-numeric characters)
              const amount = Number(task?.amount?.toString().replace(/[^\d.]/g, "") || "0");      
              return sum + (isNaN(amount) ? 0 : amount); // Ensure no NaN values
            }, 0);
    
          }, [tasks]);
    return (
        <>



<MDBModal open={showDetails} onClose={() => setShowDetails(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Task details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShowDetails}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

<TaskDetails user={user} callBack={()=>getTypeTask(taskType)} task={currentTask as TaskProps}/>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShowDetails}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>












        <div className='dashboardLists d-flex md-fullWidth-noFlex' style={{maxWidth:"100vw"}}>

<div style={{width:"60%",maxWidth:"100vw",}}>
<ScrollBar style={{width:'100%',height:"max-content"}}>
<Buttons buttonsList={buttonsList}/>
</ScrollBar>
<br/>


{(taskType==="Completed" || taskType=="Paid") &&<div className={`d-flex align-items-center justify-content-between`} style={{gap:5}}>
    <div  className={`d-flex align-items-center justify-content-between`} style={{background:"white",boxShadow:"1px 1px 10px 0px lightgray",borderRadius:10,padding:10,width:"100%"}}>
    <b>Totals</b>
        <span>{formatCurrency(totalUSD, 'USD')} </span>
        |
        <span>{formatCurrency(totalNGN, 'NGN')} </span>
        |
        <span>{formatCurrency(totalEUROPE, 'EURO')} </span>
</div>
</div>}
<Divider/>

<div className='taskListCardContainer'>

    <ScrollBar style={{height:'70vh'}}>

        
        {!isLoadingTasks && tasks.length===0 && <div className='text-center' style={{padding:30}}>
            No Data Here
            </div>}
        {isLoadingTasks && [1,2,3,4,5,6].map((e:any)=>{
            return <CardSkeleton key={e}/>
        })}
    { !isLoadingTasks && tasks.map((task:any,i:number)=>(<TaskListCard user={user} setCurrentTask={setCurrentTask} setShowDetails={setShowDetails} task={task} key={i}/>))}
</ScrollBar>

</div>


</div>

{width > 950 && <div style={{width:"40%"}}>
<Quotes/>
</div>}
        </div>
        
{width < 950 && <>
<Quotes/>
</>}
        </>
    )
}

export default DashboardLists;