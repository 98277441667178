import React, { useEffect, useState } from 'react'
import Bold from '../../utils/Bold';
import ScrollBar from 'react-perfect-scrollbar';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { Task as TaskIcon } from '@mui/icons-material';
import { Divider, Skeleton } from '@mui/material';
import useInnerWidth from '../../hooks/useInnerWidth';
import { Task, Task as TaskProps } from '../../app.interface';
import { fetchTasks_completed, fetchTasks_inprogress, fetchTasks_newest, fetchTasks_paid } from './fetchTasks';
import useUser from '../../hooks/useUser';
import NoData from '../../utils/noData';
import SkeletonLoader from './boxTask.skeleton';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import TaskDetails from './taskDetails';
import { getAbsoluteNo, getCurrencyName, sortObjectsByDate } from '../../Logics/date';
import { formatCurrency } from '../Admin/userTask';

const DashboardBox: React.FC = () => {
    const [taskType, setTaskType] = useState<"In progress" | "Completed" | "Newest" | "Paid">("Newest")
    const width = useInnerWidth();
    const { user } = useUser();
    const [myWidth, setMyWidth] = useState<number>(0);
    const [tasks, setTasks] = useState<TaskProps[]>([]);

const [isLoadingTasks,setIsLoadingTasks]=useState<boolean>(true)

    const getTypeTask = async (taskType: "In progress" | "Completed" | "Newest" |"Paid") => {
        setIsLoadingTasks(true)
        let tasks_ = tasks
        switch (taskType) {
            case 'Newest':
                tasks_ = await fetchTasks_newest(user);
                break;
            case "In progress":
                tasks_ = await fetchTasks_inprogress(user);
                break;
            case "Completed":
                tasks_ = await fetchTasks_completed(user);
                break;
                case "Paid":
                    tasks_ = await fetchTasks_paid(user);
                    break;
        }
        setTasks(sortObjectsByDate(tasks_));
        setIsLoadingTasks(false);
    }

    useEffect(() => {
        // console.log("reload",taskTypes)
        setMyWidth((window.innerWidth - 20) - (document.querySelector(".sideNav") as HTMLDivElement)?.getBoundingClientRect()?.width || 0);
        getTypeTask(taskType)

    }, [taskType])

    useEffect(() => {
    }, [])
const [showDetails,setShowDetails]=useState<boolean>(false);
const [currentTask,setCurrentTask]=useState<Task>()
    const toggleShowDetails=()=>{
        setShowDetails(!showDetails)
    }
    const currency=currentTask?.currency ? currentTask?.currency:getCurrencyName(currentTask?.amount ||"")

    return (
        <>

<MDBModal open={showDetails} onClose={() => setShowDetails(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Task details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShowDetails}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

<TaskDetails user={user} callBack={()=>{
    getTypeTask(taskType)
    setShowDetails(false)
}} task={currentTask as Task}/>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShowDetails}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>














            <br />
            <MDBDropdown>

                <MDBDropdownToggle tag="div">
                    <Bold style={{ padding: '20px' }}>{taskType}</Bold>
                </MDBDropdownToggle>

                <MDBDropdownMenu>
                    <MDBDropdownItem onClick={() => {
                        setTaskType("In progress")
                    }} link>In progress</MDBDropdownItem>
                    <MDBDropdownItem onClick={() => {
                        setTaskType("Completed")
                    }} link>Completed</MDBDropdownItem>
                    <MDBDropdownItem onClick={() => {
                        setTaskType("Newest")
                    }} link>Newest</MDBDropdownItem>

<MDBDropdownItem onClick={() => {
                        setTaskType("Paid")
                    }} link>Paid</MDBDropdownItem>

                </MDBDropdownMenu>
            </MDBDropdown>
            <ScrollBar style={{ maxWidth: width > 700 ? myWidth : '96vw', height: "max-content" }}>

                <div style={{ width: 'max-content' }}>

                    <div className='dashboardBoxs d-flex align-items-center'>
                    {tasks.length==0 && !isLoadingTasks && <div style={{padding:20,borderRadius:10,background:"white",maxWidth:270}} className='d-flex justify-content-center'><NoData>No task available of <span style={{color:'var(--orrange)'}}>{taskType}</span> category</NoData></div>}
                    

{isLoadingTasks && [1,2,3,4].map((e,i:number)=>{
    return (<SkeletonLoader key={i}/>)
})}

                        {tasks.map((task:Task, i: number) => (<div onClick={()=>{
                            setCurrentTask(task)
                            setShowDetails(true)
                        }} key={i} className='box' style={{minHeight:"max-content",height:"max-content"}}>
                            <div style={{ gap: 10, padding: 4 }} className='d-flex '>
                                <div className='d-flex align-items-center justify-content-center' style={{ borderRadius: 10, width: 30, height: 30, padding: 10, "background": 'rgba(0,0,200,0.09)' }}><TaskIcon style={{color: 'var(--blue)'}}/></div>

                                <Bold style={{fontSize:'small'}}>Start <MDBBadge color='primary' style={{color:"white"}}>{task?.startTime}</MDBBadge> | Finish <MDBBadge  style={{color:"white"}} color='primary'>{task?.finishTime}</MDBBadge></Bold></div>

                            <Divider />
                            <h4 style={{ padding: 5, marginTop: 3 }}>
                                <span style={{ fontWeight: '350' }}>
                                    {task?.heading} - {formatCurrency(getAbsoluteNo(task.amount),currency||"USD")}
                                </span>
                            </h4>
                       <div className='d-flex justify-content-between'>     <span style={{ fontSize: 'small', padding: 5 }}>
                               {task.paidAt ? "Paid date" +  task.paidAt:
                                "Dateline" + task.dateLine}
                            </span >

                            <MDBBadge color='dark' style={{height:"max-content",color:"white"}}>
                                 {task.status}
                            </MDBBadge>
</div>


                        </div>
                        ))}

                    </div>


                </div>
            </ScrollBar>
        </>
    )
}

export default DashboardBox;